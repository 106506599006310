module.exports = {
  siteTitle: 'Suh Fangmbeng',
  siteDescription:
    'Suh Fangmbeng is a solutions-focused, hands-on and goal-oriented software engineer with 10+ years of experience in implementing advanced technology and business solutions in public and private industries. I demonstrate strong analytical and problem-solving skills and the ability to follow through with projects from inception to completion.',
  siteKeywords:
    'Suh Fangmbeng, suhfangmbeng, software engineer, web developer, javascript, python, java',
  siteUrl: 'https://suhfangmbeng.com/',
  siteLanguage: 'en_US',
  // googleAnalyticsID: 'G-1N70Q9EXTF',
  // googleVerification: 'DCl7VAf9tcz6eD9gb67NfkNnJ1PKRNcg8qQiwpbx9Lk',
  name: 'Suh Fangmbeng',
  location: 'Dallas, Texas',
  email: 'me@suhfangmbeng.com',
  github: 'https://git.express-scripts.com/suhfang',
  twitterHandle: '@',
  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://git.express-scripts.com/suhfang',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/suhfangmbeng/',
    },
    // {
    //   name: 'Codepen',
    //   url: 'https://codepen.io/yashitanamdeo',
    // },
    {
      name: 'Instagram',
      url: 'https://www.instagram.com/iam.derock',
    },
    // {
    //   name: 'Twitter',
    //   url: 'https://twitter.com/',
    // },
  ],

  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    // {
    //   name: 'Experience',
    //   url: '/#jobs',
    // },
    // {
    //   name: 'Projects',
    //   url: '/#projects',
    // },
    {
      name: 'Contact',
      url: '/#contact',
    },
  ],

  navHeight: 100,

  colors: {
    green: '#64ffda',
    navy: '#0a192f',
    darkNavy: '#020c1b',
  },

  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
